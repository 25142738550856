var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"nav-container"},[_c('b-container',[_c('b-navbar',{ref:"navbar",staticClass:"navbar-bg",style:({
      backgroundColor: _vm.navOpen ? 'white' : 'white',
      width: '100%',
      position: 'fixed',
      margin: '0 auto'
    }),attrs:{"fluid":"","toggleable":"xl"}},[_c('b-navbar-brand',[_c('inline-svg',{style:(_vm.dynamicBrandStyle),attrs:{"src":_vm.$svg.getSvgUrl('logo-acconomy-grey.svg')},on:{"click":_vm.handleBrandClick}})],1),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}},[_c('inline-svg',{attrs:{"src":_vm.$svg.getSvgUrl('ic-menu.svg')}})],1),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""},model:{value:(_vm.navOpen),callback:function ($$v) {_vm.navOpen=$$v},expression:"navOpen"}},[_c('b-navbar-nav',{style:({
          opacity: _vm.navOpen ? 1 : 0
        })},_vm._l((_vm.navItems),function(item){return _c('HeaderLink',{key:item.svg,class:{
            purpleHover: _vm.currentPath === item.route
          },attrs:{"link":item,"pageType":_vm.pageType,"navOpen":_vm.navOpen},on:{"update-nav-open":_vm.updateNavOpen}})}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }