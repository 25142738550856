<template>
  <header class="nav-container">
    <b-container>
      <b-navbar fluid toggleable="xl" ref="navbar" class="navbar-bg" :style="{
        backgroundColor: navOpen ? 'white' : 'white',
        width: '100%',
        position: 'fixed',
        margin: '0 auto'
      }">
        <b-navbar-brand>
          <inline-svg @click="handleBrandClick" :src="$svg.getSvgUrl('logo-acconomy-grey.svg')"
            :style="dynamicBrandStyle" />
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse">
          <inline-svg :src="$svg.getSvgUrl('ic-menu.svg')" />
        </b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav v-model="navOpen">
          <b-navbar-nav :style="{
            opacity: navOpen ? 1 : 0
          }">
            <HeaderLink :class="{
              purpleHover: currentPath === item.route
            }" v-for="item in navItems" :key="item.svg" :link="item" :pageType="pageType" :navOpen="navOpen"
              @update-nav-open="updateNavOpen" />
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </b-container>
  </header>
</template>

<script>
import breakpoints from "@/constants/breakpoints";
const HeaderLink = () => import("@/components/HeaderLink");

export default {
  name: "Header",

  components: {
    HeaderLink
  },

  data() {
    return {
      navOpen: false,
      pageYOffset: 0,
      dynamicBrandStyle: {}
    };
  },

  computed: {
    xl: () => breakpoints.xl,

    viewWidth() {
      return this.$store.state.base.viewWidth;
    },

    currentPath() {
      return this.$route.path;
    },

    onHomePage() {
      return this.currentPath == "/";
    },

    onEuroFinansPage() {
      return this.currentPath === "/eurofinans";
    },

    onPrioritetFinansPage() {
      return this.currentPath === "/prioritetfinans";
    },

    onAllHomePages() {
      return (
        this.onHomePage || this.onEuroFinansPage || this.onPrioritetFinansPage
      );
    },
    pageType() {
      if (this.onAllHomePages) return "Startsida";
      if (this.currentPath === "/fakturerings-bokforingsprogram") return "Fakturerings-& bokföringsprogram";
      if (this.currentPath === "/fragor-svar") return "Frågor & svar";
      if (this.currentPath === "/kontaktaoss") return "kontaktaoss";
      if (this.currentPath === "/skapa-konto") return "Skapa konto";
      return "404-sida";
    },

    navItems() {
      return [
        {
          route: "/fakturerings-bokforingsprogram",
          text: "Fakturerings-& bokföringsprogram",
          to: "/fakturerings-bokforingsprogram"
        },
        {
          route: "/fragor-svar",
          text: "Frågor & svar",
          to: "/fragor-svar"
        },
        {
          route: "/kontaktaoss",
          text: "Kontakta oss",
          to: "/kontaktaoss"
        },
        // {
        //   route: "/blogg",
        //   text: "Tips & råd",
        //   to: "/blogg"
        // },
        {
          route: "/login",
          text: "Logga in",
          href: process.env.VUE_APP_ACCONOMY_LOGIN_URL,
          rel: "nofollow"
        },
        {
          route: "/skapa-konto",
          text: "Bli kund",
          href: process.env.VUE_APP_ACCONOMY_CREATE_ACCOUNT_URL,
          rel: "nofollow noopener"
        }
      ];
    }
  },

  methods: {
    updateNavOpen(state) {
      this.navOpen = state
    },
    routeSvgs() {
      return {
        svgs: {
          support:
            this.currentPath === "/kontaktaoss"
              ? "ic-support-purple.svg"
              : "ic-support.svg",
          login:
            this.currentPath === "/login"
              ? "ic-log-in-purple.svg"
              : "ic-log-in.svg",
          createAccount:
            this.currentPath === "/skapa-konto"
              ? "ic-account-purple.svg"
              : "ic-account.svg"
        }
      };
    },

    handleBrandClick() {
      this.$gtm.trackEvent({
        event: "acconomy_logo",
        logoClick: "acconomyLogo",
        placement: "header",
        pageType: this.pageType
      });
      if (this.$store.state.base.visitedEuroFinans) {
        if (this.onEuroFinansPage) return;
        this.$router.push("/eurofinans");
        return;
      }
      if (this.$store.state.base.visitedPrioritetFinans) {
        if (this.onPrioritetFinansPage) return;
        this.$router.push("/prioritetfinans");
        return;
      }

      if (this.onHomePage && this.navOpen) {
        this.navOpen = false;
        return;
      }

      if (this.onAllHomePages) return;

      this.navOpen = false;
      this.$router.push("/");
    },

    // handleMenuLinkClick(route) {
    //   if (this.currentPath === route) {
    //     if (this.viewWidth >= this.lg) return;
    //     return;
    //   }

    //   if (route === "/login") {
    //     if (this.viewWidth > this.lg) {
    //       window.open(process.env.VUE_APP_ACCONOMY_LOGIN_URL, "_blank");
    //       return;
    //     }

    //     this.navOpen = !this.navOpen;
    //     window.open(process.env.VUE_APP_ACCONOMY_LOGIN_URL, "_blank");
    //     return;
    //   }

    //   this.$router.push(route);
    // },

    handleHeaderScroll() {
      if (this.viewWidth > this.xl) return;
      this.pageYOffset = window.pageYOffset;
      const navbar = document.querySelector(".navbar");

      if (window.pageYOffset > 1) {
        navbar.classList.add("scrolling");
      } else {
        navbar.classList.remove("scrolling");
      }

      if (
        navbar.classList.contains("scrolling") &&
        !this.navOpen &&
        this.viewWidth < this.xl
      ) {
        // this.dynamicBrandStyle = { display: "none" };
      } else {
        this.dynamicBrandStyle = { display: "inline" };
      }
    },

    disableScroll() {
      // Get the current page scroll position
      const scrollTop = this.pageYOffset || document.documentElement.scrollTop;
      const scrollLeft =
        this.pageYOffset || document.documentElement.scrollLeft;

      // If any scroll is attempted, set this to the previous value
      window.onscroll = function () {
        window.scrollTo(scrollLeft, scrollTop);
      };
    },

    enableScroll() {
      window.onscroll = function () { };
    }
  },

  watch: {
    viewWidth(newVal) {
      if (newVal >= this.xl) {
        this.navOpen = false;
        this.dynamicBrandStyle = { display: "inline" };
      }
    },

    navOpen(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.dynamicBrandStyle = { display: "inline" };
        }, 100);
        this.disableScroll();
      } else if (!newVal) {
        if (this.pageYOffset > 0) {
          // this.dynamicBrandStyle = { display: "none" };
        }
        this.enableScroll();
      } else {
        this.dynamicBrandStyle = { display: "inline" };
        this.enableScroll();
      }
    }
  },

  mounted() {
    window.addEventListener("scroll", this.handleHeaderScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleHeaderScroll);
  }
};
</script>
